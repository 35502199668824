<template>
  <section class="dtc-main-section input-container">
    <h4>
      服務期滿已離任(意即離開原民族及離島地區服務，包括退休等)的養成公費醫事人員請填寫該份表單(共
      18 題)
    </h4>
    <header
      class="dtc-2-column header-dark"
      style="text-align:center;height:30px;"
    >
      <div>問題</div>
      <div>回答</div>
    </header>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>1</div>
        <div>
          第一個服務地點是衛生所者請填:
          <br />請問您當時在衛生所任職時遇到的問題或挑戰有哪些呢?(請選擇最重要的三項，接續填
          4)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q1"
          :options="q1Opts"
          @change="updateItems('q1')"
          :disabled="isQ2Exist || isQ3Exist"
        ></b-form-checkbox-group>
        <b-input v-model="q1Other" :disabled="isQ2Exist || isQ3Exist"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>2</div>
        <div>
          第一個服務地點是部立醫院者請填:
          <br />請問您當時在部立醫院任職時遇到的問題或挑戰有哪些呢?(請選擇最重要的三項，接續填4)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q2"
          :options="q2Opts"
          @change="updateItems('q2')"
          :disabled="isQ1Exist || isQ3Exist"
        ></b-form-checkbox-group>
        <b-input v-model="q2Other" :disabled="isQ1Exist || isQ3Exist"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>3</div>
        <div>
          第一個服務地點是其他醫事機構者請填:
          <br />請問您當時在 其他醫事機構(非衛生所及醫院)
          服務時遇到的問題或挑戰有哪些呢?(請選擇最重要的三項，接續填4)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q3"
          :options="q3Opts"
          @change="updateItems('q3')"
          :disabled="isQ1Exist || isQ2Exist"
        ></b-form-checkbox-group>
        <b-input v-model="q3Other" :disabled="isQ1Exist || isQ2Exist"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>4</div>
        <div>
          當初在決定第一個服務地點時，您的決定是受哪些因素影響呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q4"
          :options="q4Opts"
          @change="updateItems('q4')"
        ></b-form-checkbox-group>
        <b-input v-model="q4Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>5</div>
        <div>
          整體來看，您在第一個服務單位的適應過程是
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q5"
          :options="q5Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>6</div>
        <div>
          若於學生時期 提早讓您下鄉見實習，您認為對下鄉後的適應會有幫助嗎?
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q6"
          :options="q6Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>7</div>
        <div>
          服務時期，如果曾考慮過放棄公費身份，請問是因為哪些原因呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q7"
          :options="q7Opts"
          @change="updateItems('q7')"
        ></b-form-checkbox-group>
        <b-input v-model="q7Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>8</div>
        <div>
          服務時期，您的親密關係或家庭生活是否曾經因您的服務義務而有所影響呢?
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q8"
          :options="q8Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>9</div>
        <div>
          在服務時期，您是否曾因公費身分提供醫療照護而感到自卑呢?
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q9"
          :options="q8Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>10</div>
        <div>
          服務時期，您遇到的生活適應困難有哪些呢?
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q10"
          :options="q10Opts"
        ></b-form-checkbox-group>
        <b-input v-model="q10Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>11</div>
        <div>
          服務時期，若您考慮或轉換過服務單位，請問是因為哪些原因呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q11"
          :options="q11Opts"
          @change="updateItems('q11')"
        ></b-form-checkbox-group>
        <b-input v-model="q11Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>12</div>
        <div>
          請問您離開原民族及離島地區服務的原因為何呢?(意即留在原民族及離島地區繼續任職)呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q12"
          :options="q12Opts"
          @change="updateItems('q12')"
        ></b-form-checkbox-group>
        <b-input v-model="q12Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>13</div>
        <div>
          請問怎麼樣的改變會促使您留任意願提升呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q13"
          :options="q13Opts"
          @change="updateItems('q13')"
        ></b-form-checkbox-group>
        <b-input v-model="q13Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>14</div>
        <div>
          在服務期間，您是否有過跟公部門反映公費相關問題(例如:人事管理不公、合約內容問題等)，結果是:
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q14"
          :options="q14Opts"
        ></b-form-radio-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>15</div>
        <div>
          需求評估:<br />服務時期，您會希望有以下哪些協助呢(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q15"
          :options="q15Opts"
          @change="updateItems('q15')"
        ></b-form-checkbox-group>
        <b-input v-model="q15Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>16</div>
        <div>
          請問您對現有的養成公費政策有沒有任何想法及意見想提出呢?
        </div>
      </div>
      <div>
        <div class="mb-2">簡答:</div>
        <b-form-textarea
          v-model="q16Other"
          placeholder="請輸入"
          rows="3"
          max-rows="9"
        ></b-form-textarea>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>17</div>
        <div>
          意願調查:<br />請問您願意擔任小手們的輔導老師，當學弟妹們有任
          何課業或是公費問題時想尋求建議時可以詢問您嗎?
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q17"
          :options="q17Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>18</div>
        <div>
          意願調查:<br />請問您願意接受我們的訪談，讓您的公費經歷可以讓後續的學弟妹的做參考嗎?
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q18"
          :options="q17Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <b-button
      size="sm"
      variant="success"
      class="mr-2"
      style="display:inline-block;width:100px;height:40px;margin-top:20px;margin-left:750px"
      @click="submitAnswer"
      v-show="!closeBtn"
    >
      <i class="fas fa-save mr-1"></i>儲存問卷
    </b-button>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const q1Opts = [
  "1 自己的專科 無法發揮及精進",
  "2 對公衛保健很陌生",
  "3 對公衛保健沒有興趣",
  "4 業務包山包海、很繁雜",
  "5 沒有其他專科同儕可以互動",
  "6 有 人事/人際問題",
  "7 病人數偏少",
  "8 薪資福利等誘因較醫院來的低",
  "9 病人會對醫生或單位有不信任感",
  "10 其他",
];
const q2Opts = [
  "1 需要輪班",
  "2 需要支援其他科別",
  "3 院內行政僵化，包括主管行政風格等",
  "4 有 人事/人際問題",
  "5 病人數偏少",
  "6 沒有額外福利，例如服務年資加成計算、偏鄉加給等",
  "7 病人會對醫生或單位有不信任感",
  "8 其他:",
];
const q3Opts = [
  "1 自己的專科 無法發揮 及精進",
  "2 沒有繼續進修或訓練的機會",
  "3 業務包山包海、 很繁雜",
  "4 沒 有其他專科同儕可以互動",
  "5 有人事 人際問題",
  "6 病人數偏少",
  "7 薪資福利等誘因較醫院來的低",
  "8 病人會對醫生或單位有 不信任",
  "9 其他",
];
const q4Opts = [
  "1 只有這裡有缺",
  "2 當地的生活機能，包括交通、休閒等",
  "3 當地的親密伴侶職涯發展有關的資源",
  "4 當地的子女教養資源",
  "5 可否就近照顧父母、配偶子女等家人",
  "6 服務單位內的業務性質與工作量",
  "7 薪水、福利",
  "8 其他",
];
const q5Opts = ["1 很順利", "2 還好", "3 不算順利", "4 非常波折"];
const q6Opts = ["1 很有幫助", "2 有一點幫助", "3 完全沒幫助"];
const q7Opts = [
  "1 沒有想過",
  "2 影響親密關係的發展",
  "3 影響未來子女教養規劃",
  "4 未來生活環境不太理想",
  "5 未來職涯規劃受限",
  "6 與自己想學專業不符",
  "7 合約內容與其他類公 費生相比太苛刻",
  "8 其他",
];
const q8Opts = ["1 每次都會", "2 大部分時會", "3 不一定", "4 幾乎不會"];
const q10Opts = [
  "1 當地的生活機能差",
  "2 與親密伴侶或家人分隔兩地",
  "3 找不到合適的住家，包括租屋不便等",
  "4 家人不適應當地生活",
  "5 很難結交朋友",
  "6 難以融入當地生活文化，包括溝通困難等",
  "7 其他",
];
const q11Opts = [
  "1 沒有考慮/轉換過",
  "2 影響親密關係的發展",
  "3 影響子女教養規劃",
  "4 生活環境不太理想",
  "5 與自己想學專業不符",
  "6 不適應服務單位的工作",
  "7 不適應服務單位的人事行政管理",
  "8 不適應服務單位當地的風俗民情",
  "9 薪水福利考量",
  "10 其他",
];
const q12Opts = [
  "1 年齡已屆退休年紀",
  "2 影響親密關係維持",
  "3 影響子女教養規劃",
  "4 影響家人的照護安排",
  "5 親密伴侶職涯發展受限",
  "6 生活環境和機能不太理想",
  "7 專業職涯規劃受限",
  "8 不適應服務單位的工作",
  "9 不適應服務單位的人事行政管理",
  "10 薪水福利考量",
  "11 其他",
];
const q13Opts = [
  "1 協助親密伴侶職涯發展",
  "2 提升當地子女的教養資源",
  "3 提升當地長者照護資源",
  "4 改善當地生活環境",
  "5 改善服務單位的執業環境(包括:人事、工作量等)",
  "6 提升薪資、福利",
  "7 其他",
];

const q14Opts = [
  "1 回應很快也很有幫助",
  "2 回應很慢但很有幫助",
  "3 回應很快但沒有幫助",
  "4 回應很慢又沒有幫助",
  "5 近乎不理不睬",
  "6 沒有反映過",
];

const q15Opts = [
  "1 公費生的資訊交流平台",
  "2 公費生的實 體互動",
  "3 專科精進輔導",
  "4 公衛保健專業輔導",
  "5 行政管理輔導",
  "6 人事/人際溝通協調訓練",
  "7 其他",
];
const q17Opts = ["1 願意，請聯絡我", "2 不願意"];
export default {
  data() {
    return {
      q1: [],
      q1Opts,
      q1Other: "",
      q2: [],
      q2Opts,
      q2Other: "",
      q3: [],
      q3Opts,
      q3Other: "",
      q4: [],
      q4Opts,
      q4Other: "",
      q5: "",
      q5Opts,
      q6: "",
      q6Opts,
      q7: [],
      q7Opts,
      q7Other: "",
      q8: "",
      q8Opts,
      q9: "",
      q10: [],
      q10Opts,
      q10Other: "",
      q11: [],
      q11Opts,
      q11Other: "",
      q12: [],
      q12Opts,
      q12Other: "",
      q13: [],
      q13Opts,
      q13Other: "",
      q14: "",
      q14Opts,
      q15: [],
      q15Opts,
      q15Other: "",
      q16Other: "",
      q17: "",
      q17Opts,
      q18: "",
      isQ1Exist: false,
      isQ2Exist: false,
      isQ3Exist: false,
      closeBtn: false,
    };
  },
  props: ["allData"],
  methods: {
    updateItems(key) {
      requestAnimationFrame(() => {
        if (this[key].length < 4) return;
        this[key].splice(3);
      });
    },
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    async submitAnswer() {
      let RecordQ5 = {};
      RecordQ5.Question_01 = this.getFinalAnswer(this.q1, this.q1Opts);
      RecordQ5.Question_01_Other = this.q1Other;
      RecordQ5.Question_02 = this.getFinalAnswer(this.q2, this.q2Opts);
      RecordQ5.Question_02_Other = this.q2Other;
      RecordQ5.Question_03 = this.getFinalAnswer(this.q3, this.q3Opts);
      RecordQ5.Question_03_Other = this.q3Other;
      RecordQ5.Question_04 = this.getFinalAnswer(this.q4, this.q4Opts);
      RecordQ5.Question_04_Other = this.q4Other;
      RecordQ5.Question_05 = this.getFinalAnswer(this.q5, this.q5Opts);
      RecordQ5.Question_06 = this.getFinalAnswer(this.q6, this.q6Opts);
      RecordQ5.Question_07 = this.getFinalAnswer(this.q7, this.q7Opts);
      RecordQ5.Question_07_Other = this.q7Other;
      RecordQ5.Question_08 = this.getFinalAnswer(this.q8, this.q8Opts);
      RecordQ5.Question_09 = this.getFinalAnswer(this.q9, this.q8Opts);
      RecordQ5.Question_10 = this.getFinalAnswer(this.q10, this.q10Opts);
      RecordQ5.Question_10_Other = this.q10Other;
      RecordQ5.Question_11 = this.getFinalAnswer(this.q11, this.q11Opts);
      RecordQ5.Question_11_Other = this.q11Other;
      RecordQ5.Question_12 = this.getFinalAnswer(this.q12, this.q12Opts);
      RecordQ5.Question_12_Other = this.q12Other;
      RecordQ5.Question_13 = this.getFinalAnswer(this.q13, this.q13Opts);
      RecordQ5.Question_13_Other = this.q13Other;
      RecordQ5.Question_14 = this.getFinalAnswer(this.q14, this.q14Opts);
      RecordQ5.Question_15 = this.getFinalAnswer(this.q15, this.q15Opts);
      RecordQ5.Question_15_Other = this.q15Other;
      RecordQ5.Question_16 = this.q16Other;
      RecordQ5.Question_17 = this.getFinalAnswer(this.q17, this.q17Opts);
      RecordQ5.Question_18 = this.getFinalAnswer(this.q18, this.q17Opts);

      let obj = {};
      obj.RecordQ5 = RecordQ5;
      // obj.RecordBase = this.allData.RecordBase;
      obj.RecordBase = store.editItem.RecordBase;
      try {
        await window.axios.post("LocalRecord/SetAnswer", obj);
        this.$root.$emit("get-newestsurvey-data");
        this.$bvToast.toast(`儲存成功`, {
          title: "問卷調查",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    fillForm() {
      let items = { ...this.allData.RecordQ5 };
      this.q1 = this.getOriginalAnswer(items.Question_01, this.q1Opts);
      this.q1Other = items.Question_01_Other ? items.Question_01_Other : "";
      this.q2 = this.getOriginalAnswer(items.Question_02, this.q2Opts);
      this.q2Other = items.Question_02_Other ? items.Question_02_Other : "";
      this.q3 = this.getOriginalAnswer(items.Question_03, this.q3Opts);
      this.q3Other = items.Question_03_Other ? items.Question_03_Other : "";
      this.q4 = this.getOriginalAnswer(items.Question_04, this.q4Opts);
      this.q4Other = items.Question_04_Other ? items.Question_04_Other : "";
      this.q5 = this.getOriginalAnswer(items.Question_05, this.q5Opts)[0];
      this.q6 = this.getOriginalAnswer(items.Question_06, this.q6Opts)[0];
      this.q7 = this.getOriginalAnswer(items.Question_07, this.q7Opts);
      this.q7Other = items.Question_07_Other ? items.Question_07_Other : "";
      this.q8 = this.getOriginalAnswer(items.Question_08, this.q8Opts)[0];
      this.q9 = this.getOriginalAnswer(items.Question_09, this.q8Opts)[0];
      this.q10 = this.getOriginalAnswer(items.Question_10, this.q10Opts);
      this.q10Other = items.Question_10_Other ? items.Question_10_Other : "";
      this.q11 = this.getOriginalAnswer(items.Question_11, this.q11Opts);
      this.q11Other = items.Question_11_Other ? items.Question_11_Other : "";
      this.q12 = this.getOriginalAnswer(items.Question_12, this.q12Opts);
      this.q12Other = items.Question_12_Other ? items.Question_12_Other : "";
      this.q13 = this.getOriginalAnswer(items.Question_13, this.q13Opts);
      this.q13Other = items.Question_13_Other ? items.Question_13_Other : "";
      this.q14 = this.getOriginalAnswer(items.Question_14, this.q14Opts)[0];
      this.q15 = this.getOriginalAnswer(items.Question_15, this.q15Opts);
      this.q15Other = items.Question_15_Other ? items.Question_15_Other : "";
      this.q16Other = items.Question_16 ? items.Question_16 : "";
      this.q17 = this.getOriginalAnswer(items.Question_17, this.q17Opts)[0];
      this.q18 = this.getOriginalAnswer(items.Question_18, this.q17Opts)[0];
    },
  },
  mounted() {
    this.fillForm();
    this.closeBtn = store.editPerson ? store.editPerson.closeBtn : false;
    if (this.closeBtn) {
      document.querySelectorAll(".input-container input").forEach((e) => {
        e.disabled = true;
      });
    }
  },
  watch: {
    q1() {
      if (this.q1.length > 0) {
        this.isQ1Exist = true;
      } else {
        this.isQ1Exist = false;
      }
    },
    q2() {
      if (this.q2.length > 0) {
        this.isQ2Exist = true;
      } else {
        this.isQ2Exist = false;
      }
    },
    q3() {
      if (this.q3.length > 0) {
        this.isQ3Exist = true;
      } else {
        this.isQ3Exist = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: 30px auto;
  display: grid;
}
.dtc-2-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  border-top: none;
  > div {
    padding: 10px;
  }
  > div:first-child {
    border-right: 1px solid black;
    font-size: 16px;
  }
}
.header-dark {
  border: 1px solid black;
  line-height: 7px;
}
.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}
.bold-text-before {
  position: relative;
  margin-top: 30px;
  &::before {
    position: absolute;
    top: -30px;
    font-weight: 900px !important;
    content: "需求評估:";
  }
}
</style>
